const markdown = `

<details><summary>6/10/24 - 3.4.18 - Feature additions / Order Loading Speed Improvements</summary>

###### New Improvements
The ETO and Inspiring Installs section was added for project uploads and sharing. A major backend API version upgrade was also implemented for speed and security enhancements with order data.
</details>

<details><summary>6/6/24 - 3.4.17 - Authentication System Refactor</summary>

###### New Improvements
The authentication system for SpaceNET has been refactored to improve speed and security for the authentication process
</details>

<details><summary>5/2/23 - 3.4.16 - Speed and security upgrade</summary>

###### New Improvements
A major backend API version upgrade was implemented for speed and security enhancements.
</details>

<details><summary>2/4/23 - 3.4.15 - Security Controls Enhancement</summary>

###### New Improvements
An upgrade to all security controls in SpaceNET was implemented to adhere to CMMC initiative standards.
</details>

<details><summary>7/26/22 - 3.4.14 - Feature additions</summary>

###### New Improvements
A column filtering feature was added for the report details view, and the ability to download a .csv of all Interterritorial projects was added.
</details>

<details><summary>6/6/22 - 3.4.13 - Configura license report addition</summary>

###### New Improvements
The report for licensed configura users was added under the tools menu.
</details>

<details><summary>6/29/22 - 3.4.12 - Search improvements</summary>

###### Bug Fixes
When pasting order numbers into search bars, spaces will be pasted and affect the results. All search bars now strip any extra space that was pasted from a copy.
</details>

<details><summary>6/6/22 - 3.4.11 - Warranty Submission improvements</summary>

###### New Improvements
In an effort to improve the quality of registered warranty numbers, a new app was launched for submission of warranties. [Warranty Submissions](https://warranty.spacesaver.com/)
</details>

<details><summary>6/6/22 - 3.4.10 - Dashboard view feature addition</summary>

###### New Improvements
The addition of a dashboard view brings order search, order notifications, and collaborate questions to the home page, as well as an improved menu system.
</details>

<details><summary>5/16/22 - 3.4.9 - Interterritorial projects submission fix</summary>

###### Bug Fixes
Certain users were seeing an issue with submitting projects. This was caused by a comma not being stripped from a price field. This is now resolved.
</details>

<details><summary>4/25/22 - 3.4.8 - Bug fix when printing Spacenet to paper</summary>

###### Bug Fixes
The header and footer menus are now removed when a user prints a spacenet page to paper to create a cleaner print.
</details>

<details><summary>4/5/22 - 3.4.7 - Warranty information adjustments</summary>

###### Bug Fixes
The warranty submission date was affecting all warranties and is now functioning properly.
</details>

<details><summary>3/15/22 - 3.4.6 - Reports page feature additions</summary>

###### New Improvements
It is now possible to pull sales reports on the list of all sales reps, when it was previously only available while viewing a single sales rep.
</details>

<details><summary>3/02/22 - 3.4.5 - Help tour bug fixes</summary>

###### Bug Fixes
The help tour has been fixed to accommadate interface improvements made on the marketing materials and service and installation pages.
</details>

<details><summary>2/14/22 - 3.4.4 - Bundle sheet viewer enhancements</summary>

###### Bug Fixes
The viewer for the bundle sheet has now been fixed to allow searching and paginating through multiple pages of shipment information.
</details>

<details><summary>2/1/22 - 3.4.3 - Media room authentication functionality</summary>

###### Bug Fixes
Single sign on functionality to the media room has been revamped to eliminate issues authenticating after long Spacenet 3 sessions.
</details>

<details><summary>1/15/22 - 3.4.2 - Service and installation improvements</summary>

###### New Improvements
New user interface changes on the service and installation page will help in filtering documents by type. Improvements to asset tagging were also made throughout the entire library of field service documents.

</details>

<details><summary>12/15/21 - 3.4.1 - Marketing materials and search improvements</summary>

###### New Improvements
New user interface changes on the marketing materials page will help in filtering documents by type.

###### Bug Fixes
Documents were cross-referrenced from Spacenet 2 and improved tagging was applied to all media.
</details>

<details><summary>10/24/21 - 3.3.13 - Issue with password reset notifications</summary>

###### Bug Fixes
Area Contractor users were having an issue with the email confirmation while resetting passwords. 
</details>


<details><summary>10/12/21 - 3.3.12 - Addition of ‘search via shipping date’ feature</summary>

###### New Features
A user now has the ability to search for orders either via 'entered' or 'promised' date.
</details> 


<details><summary>10/6/21 - 3.3.11 - Issue with order acknowledgement formatting</summary>

###### Bug Fixes
A small number of order acknowledgments had formatting issues due to a Terms and Conditions verbiage addition. 
</details> 

<details><summary>9/22/21 - 3.3.10 - New features for IT project submission</summary>

###### New Features
The area contractor is now auto-selected based on the entered zip code. A user can also now choose to skip the architect and general contractor views.
</details> 

<details><summary>9/1/21 - 3.3.9 - Issues with sales percentages on interterritorial orders</summary>

###### Bug Fixes
The sales rep sales percentage is now corrected on orders that are split between sales reps. This issue is resolved.
</details> 

<details><summary> 8/23/21 - 3.3.8 - Missing orders for some sales rep ids</summary>

###### Bug Fixes

A sales rep is now able to select a date and click search to view all orders. 
   
</details> 




<details><summary> 8/19/21 - 3.3.7 - Report data incorrect for an admin user</summary>

###### Bug Fixes

An admin user can now view all report data related to their company.
   
</details> 




<details><summary>8/16/21 - 3.3.6 - Changes to Expedited Order Functionality</summary>

###### Bug Fixes

Expedited order functionality was open initially to all. This is now permissioned to specific users at a company. Sales Reps can view expedited orders attached to their orders under the order details.
   
</details> 



<details><summary>8/12/21 - 3.3.5 - Salesforce case creation from new comments and activity </summary>

###### Bug Fixes

Salesforce case creation bugs have been solved for Order, RFR, and RGA comments.
   
</details> 




<details><summary>8/11/21 - 3.3.4 - Email notifications</summary>

###### Bug Fixes

Email notifications will be sent to the receiving end company for interterritorial projects.  
   
</details> 



<details><summary>8/9/21- 3.3.3 - User Notification of comments</summary>

###### New Features

Individuals will now receive an email notification when they post a question in the Collaborate section and another user comments on their question. This functionality is only available on Spacenet 3.
</details> 



<details><summary>8/5/21 - 3.3.2 - User Interface Modifications on the login screen </summary>

###### New Features

Users can now click the eye icon next to the password field to view their hidden password.
   
<img src="/login-example.jpg" style="border-radius: 4px" alt="" width="400" height="auto">
</details> 





<details><summary>8/3/21 - 3.3.1 - Marketing Materials category and content additions</summary>

###### New Improvements

Marketing Materials > Shelving now has the addition of two new categories, "GROW Drying and Cart Rack" and "Nantucket Drawers and Trays"
</details> 




<details><summary>7/30/21 - 3.3.0 - Acknowledgment and Invoice feature additions</summary>

###### New Features

Certain users will see the addition of two new tabs on the order listings page, one for acknowledgments, and one for invoices. The unprinted acknowledgments and unprinted invoices from Spacenet 2 are shown here. This repository of documents is also in sync with Spacenet 2, and contains every document you did not print that has been posted. 
 
<img src="/release-images/ack-tabs.jpg" style="border-radius: 4px" alt="" width="100%" height="auto">
</details> 



<details><summary>7/20/21 - 3.2.2 - Ability to see “new” activity on the Order Listings page</summary>

###### New Features
All orders with a light blue highlight have had new acknowledgements or invoices posted to them since your last login.  The highlight will reset after one workday.  This new feature provides the ability to easily identify new orders that have been posted since a user last viewed their order listings.
</details> 


<details><summary>7/18/21 - 3.2.1 - Tracking information for shipped orders</summary>

###### New Features
A user can now view the tracking number for partially shipped or shipped orders in the Order Details page.  There are clickable links for 10 different UPS and FedEx methods.
</details> 


`

export default markdown;